<template>
  <div>
    <AppFixedPageTitle
      title="Preço de concreto"
      icon="/img/icons/icons8/ios/briefcase.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="init({})"
          :showCompanyPlants="true"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
                >Dimensão Máxima</label
              >
              <puzl-select
                v-model="gravel_filter"
                :items="$_maximum_sizes"
                :multiple="false"
                class="select-xl new-default-black-font"
                label="description"
                @input="handleFilterGravels"
                placeholder="Selecione"
              />
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
                >Slump/Flow</label
              >
              <puzl-select
                v-model="slump_filter"
                :items="$_slump_flows"
                :multiple="false"
                class="select-xl new-default-black-font"
                label="nomenclature"
                @input="handleFilterSlumps"
                placeholder="Selecione"
              />
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
                >Tipo</label
              >
              <puzl-select
                v-model="mix_types_filter"
                :items="$_mix_types"
                :multiple="false"
                class="select-xl new-default-black-font"
                label="technical_description"
                @input="handleFilterMixTypes"
                placeholder="Selecione"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            link="/commercial/concrete-price/parameter"
            text="parâmetros"
            type="primary"
            icon="/img/icons/icons8/ios/adjust_white.png"
          />
          <!-- Relatorios sera criado em breve -->
          <!-- <AppPageHeaderActionsButton
            link=""
            text="relatórios"
            type="dark"
            icon="/img/icons/icons8/ios/graph-report.png"
          /> -->
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :isShowViewTypes="isDesktop"
      :items="tabSelectItem"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="init({})"
        placeholder="ORDENAR"
        variant="text-only"
      />
    </AppTabSelect>

    <!-- Barra de busca -->
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loading">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <!-- Listagem em cards -->
      <Cards
        v-show="listType == 'cards'"
        :loading="loading"
        :concrete_prices="concrete_prices"
        :calculateGrossMargin="calcGrossMargin"
        :showParametters="showParametters"
        :handleModalPuzlAdjust="handleModalPuzlAdjust"
        :copy="copy"
      />
      <Table
        v-show="listType == 'table'"
        :loading="loading"
        :concrete_prices="concrete_prices"
        :calculateGrossMargin="calcGrossMargin"
        :showParametters="showParametters"
        :handleModalPuzlAdjust="handleModalPuzlAdjust"
      />
      <PuzlEmptyData v-if="!loading && !concrete_prices.length"></PuzlEmptyData>
      <ScrollPagination :config="pagination" @nextPage="fetch" />
    </div>
    <ModalPuzlAdjust ref="modalPuzlAdjust" />
  </div>
</template>

<script>
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppTabSelect,
  AppSelect,
} from "../../../../components/AppGlobal";
import { mapGetters } from "vuex";
import ScrollPagination from "@/components/ScrollPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import globalFilter from "@/mixins/globalFilter";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlSelect from "@/components/PuzlSelect";
import ModalPuzlAdjust from "./Shared/_ModalPuzlAdjust";
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import { setPreferredViewMode, getPreferredViewMode } from "@/plugins/session";
import { strToNum } from "../../../../helpers";

const filters = {
  global: [],
  order_by: null,
};
export default {
  name: "IndexConcretePrice",
  mixins: [globalFilter],
  components: {
    ScrollPagination,
    SkeletonPuzlGrid,
    MultiFilter,
    PuzlEmptyData,
    BaseButtonHoverable,
    PuzlSelect,
    ModalPuzlAdjust,
    Cards,
    Table,
    PuzlSelect,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppPageHeaderActionsButtonDropItem,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppTabSelect,
    AppSelect,
  },
  data() {
    return {
      global: [],
      loading: true,
      active: true,
      once: true,
      itens_per_page: 9,
      listType: "cards",
      pagination: {
        loading: false,
        has_more: null,
        scrolled_current: false,
        items_length: 0,
      },
      filter: {},
      loadingSlumpFlow: false,
      loadingMaximumSize: false,
      loadingMixTypes: false,
      loadingSkeleton: false,
      loadingCompanyPlants: false,
      selectedCompanyPlants: [],
      filtered: null,
      searchBarFilter: initSearchBarFilterType(),
      // conferir se precisa mesmo
      slump_filter: [],
      gravel_filter: [],
      mix_types_filter: [],
      isDesktop: true,
      orderBy: {
        items: [
          {
            id: 0,
            name: "DO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [
              {
                column: "mix_designs_mixes.id",
                is_desc: 0,
              },
            ],
          },
          {
            id: 1,
            name: "DO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [
              {
                column: "mix_designs_mixes.id",
                is_desc: 1,
              },
            ],
          },
        ],
        selected: 0,
      },
    };
  },
  mounted() {
    this.listType = getPreferredViewMode() ?? "cards";
    this.loadingSlumpFlow = true;
    this.loadingMaximumSize = true;
    this.loadingCompanyPlants = true;
    this.loadingMixTypes = true;
    this.$store.dispatch("mixSlumpFlow/fetchItems").then(() => {
      this.loadingSlumpFlow = false;
    });
    this.$store.dispatch("mixMaximumSize/fetchItems").then(() => {
      this.loadingMaximumSize = false;
    });
    this.$store.dispatch("plant/fetchItems").then(() => {
      this.loadingCompanyPlants = false;
    });
    this.$store.dispatch("mixType/fetchItems").then(() => {
      this.loadingMixTypes = false;
    });
    this.once = true;
    this.filter = { ...filters };
    this.filter.status = null;
    this.filter.order_by = null;
    this.filter.global = [];
    // Variáveis usadas no hoverable button
    this.filter.status_active = false;
    this.filter.status_commercial = false;
    if (window.innerWidth <= 992) {
      this.isDesktop = false;
    }
    this.init();
  },
  computed: {
    ...mapGetters({
      concrete_prices: "concretePrice/fetch",
      $_slump_flows: "mixSlumpFlow/activeItems",
      $_maximum_sizes: "mixMaximumSize/activeItems",
      $_plants: "plant/activeItems",
      $_mix_types: "mixType/fetch",
    }),
    tabSelectItem() {
      return [
        {
          id: null,
          name: "Todos",
          selected: this.filter.status == null,
        },
        {
          id: 1,
          name: "Ativo",
          selected: this.filter.status === 1,
        },
        {
          id: 5,
          name: "Comercial",
          selected: this.filter.status === 5,
        },
      ];
    },
  },
  watch: {
    concrete_prices() {
      this.pagination.items_length = this.concrete_prices.length;
    },
  },
  methods: {
    onTabSelectItemClick(item) {
      this.filter.status = item.id;
      this.init({});
    },
    changeStatus(uuid) {
      let concrete_prices = this.concrete_prices;
      let [concrete] = concrete_prices.filter((item) => item.uuid === uuid);

      if (concrete.status === 1 || concrete.status === 3) {
        concrete.status = 2;
      } else {
        concrete.status = 1;
      }
    },
    setViewType(type) {
      this.listType = type;
      setPreferredViewMode(type);
    },
    calcGrossMargin: function (minimumPrice, mcc, tax = 0) {
      const price = strToNum(minimumPrice);
      const cmc = strToNum(mcc);
      if (price > 0) {
        if (Number(tax) > 0) {
          return price - price * (Number(tax) / 100) - cmc;
        } else {
          return price - cmc;
        }
      }
      return 0;
    },
    handleModalPuzlAdjust(item) {
      this.$refs.modalPuzlAdjust.openModal(
        item,
        this.calcGrossMargin(
          item.price.minimum_price,
          item.price_parameter["mcc_price"].price
        )
      );
    },
    showParametters(index) {
      this.$el
        .querySelector(".parametro-" + index)
        .classList.toggle("parametter-disabled");
    },
    handleFilterCompanyPlants(filter) {
      this.filter.selectedCompanyPlants = filter;
      this.selectedCompanyPlants = filter;
      this.init({});
    },
    handleFilterMixTypes(filter) {
      if (filter == null) {
        this.filter.mixTypes = null;
      }
      this.foundItem = this.$_mix_types.find((item) => item.id === filter);
      this.filter.mixTypes = [this.foundItem.technical_description];
      this.mix_types_filter = filter;
    },
    handleFilterSlumps(filter) {
      if (filter == null) {
        this.filter.slumps = null;
      }
      this.foundItem = this.$_slump_flows.find((item) => item.id === filter);
      this.filter.slumps = [this.foundItem.nomenclature];
      this.slump_filter = filter;
    },
    handleFilterGravels(filter) {
      if (filter == null) {
        this.filter.gravels = null;
      }
      this.foundItem = this.$_maximum_sizes.find((item) => item.id === filter);
      this.description = this.foundItem.short_description.split("+ ");
      this.filter.gravels = this.description;
      this.gravel_filter = filter;
    },
    handleFilterAdvancedStatus(filter) {
      if (this.filter.status === filter) {
        this.filter.status = null;
        this.filter.status_active = false;
        this.filter.status_commercial = false;
      } else {
        this.filter.status = filter;
        if (filter == 1) {
          this.filter.status_active = true;
          this.filter.status_commercial = false;
        } else {
          this.filter.status_active = false;
          this.filter.status_commercial = true;
        }
      }

      this.init({});
    },
    handleGeneralFilterClick() {
      this.fetch(this.filter);
    },
    clearFilter() {
      this.gravel_filter = null;
      this.slump_filter = null;
      this.mix_types_filter = null;
      this.filter = {
        global: [],
        range: this.defaultRange,
        status: null,
        order_by: null,
        status_active: false,
        status_commercial: false,
      };
      this.init({});
    },
    init() {
      this.filter.global = this.searchBarFilter.custom_search_values;
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
      this.fetch();
    },
    fetch(filters = this.filter, has_pagination = false) {
      this.loadingControl(has_pagination, 0);
      this.$store
        .dispatch(
          "concretePrice/fetchItems",
          this.getFilters(filters, has_pagination)
        )
        .then((response) => {
          this.pagination.has_more = response.has_more;
          this.loadingControl(has_pagination, 1);
          if (this.once) {
            this.fetch(this.filter, true);
            this.once = false;
          }
        })
        .catch((error) => {
          this.loadingControl(has_pagination, 1);
        });
    },
    /**
     * Função que irá realizar o controle das variáveis de requisição.
     *
     * @param mixed filters
     * @param boolean has_pagination
     *
     * @return object
     */
    getFilters(filters, has_pagination) {
      return {
        paginate: this.itens_per_page,
        filters: this.filter,
        has_pagination: has_pagination,
      };
    },

    changeState() {
      this.active = !this.active;
    },

    /**
     * Função que irá realizar o controle dos loadings o qual desejas trabalhar.
     * type 0 indica o início da requisição.
     *
     * @param boolean has_pagination
     * @param int type
     *
     * @return void
     */
    loadingControl(has_pagination, type = 0) {
      if (type === 0) {
        this.loading = has_pagination ? false : true;
        this.pagination.scrolled_current = has_pagination ? true : false;
        this.pagination.loading = has_pagination ? true : false;
        this.$Progress.start();
        return;
      }

      this.loading = false;
      this.pagination.scrolled_current = false;
      this.pagination.loading = false;
      this.$Progress.finish();
    },
    handleViewTypeChange(type) {
      this.listType = type;
    },
    copy(val) {
      this.$helper.copy(val);
      this.$notify({
        type: "success",
        message: "Código do traço copiado com sucesso!",
      });
    },
  },
};
</script>

<style>
.new-default-black {
  background-color: #2b2d32 !important;
  background: #2b2d32 !important;
}
.new-default-blue-font {
  color: #1a70b7 !important;
}
.new-default-black-font {
  color: #2b2d32 !important;
}
</style>
