const keys = Object.freeze({
  ENTITY_ANTICIPATION_BALANCE_HISTORY: 1,
  RECIPE_DEVOLUTION: 2,
  ENTITY_CREDIT_HISTORY: 3,
  ANTICIPATION_TRANSFER: 4,
});

const getTitle = (value) => {
  switch (value) {
    case keys.ENTITY_ANTICIPATION_BALANCE_HISTORY:
      return "Movimentações";
    case keys.RECIPE_DEVOLUTION:
      return "Devolução";
    case keys.ENTITY_CREDIT_HISTORY:
      return "Liberação de Crédito";
    case keys.ANTICIPATION_TRANSFER:
      return "Transferências";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
