<template>
  <modal size="md" :show.sync="modal" class="pb-5 modal">
    <template slot="header" class="teste">
      <h5 class="modal-title p-0 m-0 mt-1">
        <img src="/img/icons/icons8/ios/unavailable_danger.png" height="25" class="mr-2" />
        Cancelar ordem de compra
      </h5>
    </template>
    <div class="p-3">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          <span class="font-weight-500">Data</span>
        </div>
        <div class="col-6 pr-0">
          <base-input input-group-classes="">
            <InputDateTimePicker class="w-100" :disableMaxDate="false" v-model="data.date" :mode="'date'" />
          </base-input>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <span class="font-weight-500">Observação</span>
        </div>
        <div class="col-6 pr-0">
          <base-input input-group-classes="input-group-sm">
            <textarea v-model="data.justification" type="text" rows="3" class="form-control form-control-sm"
              placeholder="Justificação">
        </textarea>
          </base-input>
        </div>
      </div>
      <div class="row d-flex justify-content-end mt-4 mr-1">
        <div @click="closeModal" class="d-flex align-items-center button-cancel col-auto px-4" style="gap: 5px">
          <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px" />
          <span class="font-weight-500"> Cancelar </span>
        </div>
        <div @click="updateStatusOrder" class="d-flex align-items-center button-save col-auto py-1 ml-3 px-4" style="gap: 5px">
          <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px" />
          <span class="font-weight-500"> Salvar </span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { PurchaseOrderStatusEnum } from "@/enum/PurchaseOrderStatusEnum";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";

export default {
  name: "modalOrderDeniedJustification",
  components: {
    InputDateTimePicker,
  },
  data() {
    return {
      modal: false,
      data: {
        date: moment().format('YYYY-MM-DD'),
        justification: null
      },
      PurchaseOrderStatusEnum: PurchaseOrderStatusEnum,
    }
  },
  methods: {
    // Modal
    handleCreateModal(data) {
      this.purchaseOrder = data;
      this.modal = true;
    },
    closeModal() {
      this.modal = false
    },
    updateStatusOrder() {
      let loader = this.$loading.show();
      this.purchaseOrder.status = this.PurchaseOrderStatusEnum.CANCELED;
      this.purchaseOrder.denied_date = this.data.date;
      this.purchaseOrder.status_reason = this.data.justification;
      this.purchaseOrder.purchase_order_id = this.data.id;
      this.$store.dispatch('purchaseOrder/update', this.purchaseOrder)
        .then(() => {
          loader.hide();
          this.$emit('fetch');
          this.closeModal();
        })
        .catch(() => {
          loader.hide();
        });
    },
  },
}
</script>

<style scoped>
.modal>>>.modal-header {
  background-color: #fcf3f3 !important;
}

.modal>>>.modal-body {
  margin-top: 0 !important;
}

.modal-title {
  color: #4b4b50;
}
</style>
